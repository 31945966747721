import React from "react";
import snail from "./images/snaillabs.svg"
import img from "./images/1.svg"
import c1 from "./images/c1.svg"
import c2 from "./images/c2.svg"
import tg from "./images/tg.svg"
import pk from "./images/pk.svg"
import rd from "./images/rd.svg"
import don from "./images/don.svg"
import { NavLink } from "react-router-dom";

function Contact() {
    return (
      <div className="Contact">
        <p>Наша команда</p>
        <img galleryimg="no" className="i1" src={c1}/>
        <img galleryimg="no" className="i2" src={c2}/>
        <div>
        <div className="soc">
          <NavLink className="nav" to="https://t.me/snaillabs_tech">
          <img galleryimg="no" src={tg}/>
          </NavLink>
          <NavLink className="nav" to="https://pikabu.ru/@SnailLabs">
          <img galleryimg="no" src={pk}/>
          </NavLink>
          <NavLink className="nav" to="https://www.reddit.com/user/SnailLabs/">
          <img galleryimg="no" src={rd}/>
          </NavLink>
        </div>
        <NavLink className="nav" to="https://donate.stream/snaillabs">
        <img galleryimg="no" className="icont" src={don} />
        </NavLink>
        </div>  
      </div>
    );
  }
  
  export default Contact;