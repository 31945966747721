import React from "react";
import snail from "./images/snaillabs.svg"
import img from "./images/1.svg"
import lc from "./images/cl2.svg"
import { NavLink } from "react-router-dom";

function L_C() {
    return (
        <NavLink className="nav" to="https://forms.gle/AG1TLCBEbxxvoufU6">
            <img galleryimg="no" className="L_C" src={lc} /> 
        </NavLink>
    );
  }
  
  export default L_C;