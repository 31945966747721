import React from "react";
import snail from "./images/snaillabs.svg"
import img from "./images/1.svg"
import { NavLink } from "react-router-dom";

function Banner3() {
    return (
      <div className="Banner3">
        <NavLink className="nav" to='/publications'>
        <div>
            <p>Зарегистрироваться</p>
        </div>
        </NavLink>  
      </div>
    );
  }
  
  export default Banner3;