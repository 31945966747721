import React from "react";
import snail from "./images/snaillabs.svg"
import img from "./images/1.svg"
import pc1 from "./images/pm1.svg"
import tc1 from "./images/tm11.svg"

function M_1() {
    return (
      <div className="C1">
        <img galleryimg="no" className="c11p" src={pc1}/> 
        <img galleryimg="no" className="c11t" src={tc1} />
      </div>
    );
  }
  
  export default M_1;