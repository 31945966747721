import React from "react";
import snail from "./images/snaillabs.svg"
import img from "./images/1.svg"

function B_M() {
    return (
      <div className="B_M"> 
      </div>
    );
  }
  
  export default B_M;