import React from "react";
import snail from "./images/snaillabs.svg"

function Join() {
    return (
      <div className="Join">
        <p>Присоединяйтесь</p>  
      </div>
    );
  }
  
  export default Join;