import React from "react";
import snail from "./images/snaillabs.svg"
import img from "./images/1.svg"
import pc1 from "./images/pm3.svg"
import tc1 from "./images/tm33.svg"

function M_3() {
    return (
      <div className="C1">
        <img galleryimg="no" className="c1p" src={pc1}/> 
        <img galleryimg="no" className="c1t" src={tc1} />
      </div>
    );
  }
  
  export default M_3;