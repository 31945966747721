import React from "react";
import snail from "./images/snaillabs.svg"
import img from "./images/1.svg"
import pc1 from "./images/ps1.svg"
import tc1 from "./images/ts11.svg"

function S_1() {
    return (
      <div className="C1">
        <img galleryimg="no" className="c1p" src={pc1}/> 
        <img galleryimg="no" className="c1t" src={tc1} />
      </div>
    );
  }
  
  export default S_1;