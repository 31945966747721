import React from "react";
import logo from './logo.svg';
import acc from './images/Frame.svg';
import { NavLink } from "react-router-dom";

function Header() {
    return (
      <div className="Headerr">
        <div>
            <NavLink className="nav" to="*">
            <img galleryimg="no" src={logo}/>
            </NavLink>
            <NavLink className="nav" to="/models">
            <p class="cust_p">Модели</p>
            </NavLink>
            <NavLink to="/slicer" className="nav">
            <p>Слайсер</p>
            </NavLink>
            <NavLink to="/publications" className="nav">
            <p>Монетизация</p>
            </NavLink>
            <img galleryimg="no" class="i_h" src={acc} />
        </div>
      </div>
    );
  }
  
  export default Header;