import snail from "./images/snaillabs.svg"
import img from "./images/1.svg"
import n1 from "./images/n1.svg"
import t1 from "./images/t1.svg"
import n2 from "./images/n2.svg"
import t2 from "./images/t2.svg"
import n3 from "./images/n3.svg"
import t3 from "./images/t3.svg"

function News() {
    return (
      <div className="News">
        <div className="cont">
            <img galleryimg="no" src={n1}/>
            <img galleryimg="no" className="t1" src={t1} />
        </div>
        <div className="cont2">
            <img galleryimg="no" className="t2" src={t2} />
            <img galleryimg="no" src={n2}/>
        </div>
        <div className="cont3">
            <img galleryimg="no" src={n3}/>
            <img galleryimg="no" className="t1" src={t3} />
        </div>  
      </div>
    );
  }
  
  export default News;