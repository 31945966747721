import React from "react";
import snail from "./images/snaillabs.svg"
import img from "./images/1.svg"
import { NavLink } from "react-router-dom";

function Banner2() {
    return (
      <div className="Banner2">
        <div>
          <NavLink className="nav" to='/slicer'>
          <p>Узнать о слайсере</p>
          </NavLink>
            
        </div>  
      </div>
    );
  }
  
  export default Banner2;