import logo from './logo.svg';
import './App.css';
import Header from './Header';
import SnailLabs from './SnailLabs';
import Join from './Join';
import Banner1 from './banner';
import Banner2 from './banner2';
import Banner3 from './banner3';
import News from './news';
import Contact  from './contacts';
import B_C from './B_C';
import C_1 from './C1';
import C_2 from './C2';
import Mod from './Mod';
import L_C from './CL';
import B_S from './B_S';
import S_1 from './S1';
import S_2 from './S2';
import S_3 from './S3';
import L_S from './SL';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import B_M from './B_M';
import M_1 from './M1';
import M_2 from './M2';
import M_3 from './M3';
import L_M from './ML';

const CARDS = () =>{
  return(
    <div className='App'>
      <Header />
      <B_C />
      <C_1 />
      <C_2 />
      <Mod />
      <L_C />
    </div>
  )
}

const SLICER = () => {
  return(
    <div className='App'>
      <Header />
      <B_S />
      <S_1 />
      <S_2 />
      <S_3 />
      <L_S />
    </div>
  )
}

const MONET = () =>{
  return(
    <div className='App'>
      <Header />
      <B_M />
      <M_1 />
      <M_2 />
      <M_3 />
      <L_M />
    </div>
  )
}

const MAINPAGE = () =>{
  return(
    <div className="App">
      <Header />
        <SnailLabs />
        <Join />
        <Banner1 />
        <Banner2 />
        <Banner3 />
        <News/>
        <Contact />
    </div>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="*" element={<MAINPAGE />}/>
      <Route path='/models' element={<CARDS />} />
      <Route path='/slicer' element={<SLICER/>}/>
      <Route path='/publications' element={<MONET/>}/>
      </Routes>
      </BrowserRouter>
  );
}

export default App;
