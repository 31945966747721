import React from "react";
import snail from "./images/snaillabs.svg"
import img from "./images/1.svg"

function Mod() {
    return (
      <div className="Mod"> 
      </div>
    );
  }
  
  export default Mod;