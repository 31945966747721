import React from "react";
import snail from "./images/snaillabs.svg"
import img from "./images/1.svg"
import { NavLink } from "react-router-dom";

function Banner1() {
    return (
      <div className="Banner1">
        <NavLink className="nav" to="/models">
        <div>
            <p>Загрузить свою модель</p>
        </div>

        </NavLink>  
      </div>
    );
  }
  
  export default Banner1;