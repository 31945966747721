import React from "react";
import snail from "./images/snaillabs.svg"
import img from "./images/1.svg"
import pc2 from "./images/ps2.svg"
import tc2 from "./images/ts22.svg"

function S_2() {
    return (
      <div className="S2">
        <img galleryimg="no" className="c2t" src={tc2}/> 
        <img galleryimg="no" className="c2p" src={pc2} />
      </div>
    );
  }
  
  export default S_2;