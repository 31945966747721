import React from "react";
import snail from "./images/snaillabs.svg"

function SnailLabs() {
    return (
      <div className="SnailLabs">
        <img galleryimg="no" src={snail} />   
      </div>
    );
  }
  
  export default SnailLabs;